import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import FormLander from "../components/FormLander";
import WhyTravelNursing from "../components/WhyTravelNursing";

const SignUpFull = ({ data }) => (
  <Layout templateName="sign-up-full" headerStyle="transparent minimal">
    <Seo
      title={data.wordpressPage.yoast_meta.yoast_wpseo_title}
      description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      image={
        data.wordpressPage.featured_media &&
        data.wordpressPage.featured_media.localFile
          ? `${
              process.env.NODE_ENV === "production"
                ? data.site.siteMetadata.baseUrl
                : typeof window !== "undefined" && window.location.host
            }${
              data.wordpressPage.featured_media.localFile.childImageSharp
                .original.src
            }`
          : ""
      }
      canonical={data.wordpressPage.yoast_meta.yoast_wpseo_canonical}
    />
    <FormLander />
    <WhyTravelNursing />
  </Layout>
);

export default SignUpFull;

export const query = graphql`
  query SignUpFullQuery {
    site {
      siteMetadata {
        baseUrl
      }
    }
    wordpressPage(wordpress_id: { eq: 129 }) {
      featured_media {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;
